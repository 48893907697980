import { useEffect, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { LIST_NFT } from 'flow/transactions/listNFT.tx'
import { CANCEL_NFT_SALE } from 'flow/transactions/cancelNFTSale.tx'
import { CANCEL_ALL_NFT_SALE } from 'flow/transactions/cancelAllNFTSale.tx'
import { PURCHASE_NFT } from 'flow/transactions/purchaseNFT.tx'

import { useTransactions } from 'providers/TransactionsProvider'
import { useWallets } from 'providers/WalletsProvider'
import { waitForSealed } from 'utils/flow'
import { MIGRATE_MARKETPLACE_LISTINGS_TX } from 'flow/transactions/migrateListings.tx'
import { SETUP_MARKETPLACE } from 'flow/transactions/setupMarketplace.tx'
import { useSnackbar } from 'hooks/useSnackbar'

export enum TransactionStatus {
  LOADING,
  IDLE,
  INFLIGHT,
  DONE,
  ERROR
}

export default function useMarketplaceListing() {
  const [status, setStatus] = useState<TransactionStatus>(
    TransactionStatus.IDLE
  )

  const [isMarketplaceSetup, setIsMarketplaceSetup] = useState<boolean>(true)
  const [transactionId, setTransactionId] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { addTx } = useTransactions()
  const { userAuth, refreshWallets, connectedWalletAddress } = useWallets()
  const { openSnackbar } = useSnackbar()

  useEffect(() => {
    async function checkMarketplaceSetup() {
      try {
        const isSetup = await checkIsMarketplaceSetup(connectedWalletAddress)
        console.log({ isSetup })
        setIsMarketplaceSetup(isSetup)
      } catch (e) {
        console.error(e)
        // add papertrail(e)
        setIsMarketplaceSetup(false)
      }
    }

    checkMarketplaceSetup()
  }, [connectedWalletAddress])

  async function checkIsMarketplaceSetup(userAddress: string) {
    if (!userAddress) return false
    const res = await fcl.query({
      cadence: `
        import NonFungibleToken from 0xNFT
        import AFLNFT from 0xAFLContracts
        import AFLMarketplaceV2 from 0xAFLContracts

        access(all) fun main(userAddress: Address): AnyStruct {
          let seller = getAccount(userAddress)
          return seller.capabilities.borrow<&AFLMarketplaceV2.SaleCollection>(/public/AFLMarketplaceSaleCollectionV2)
        }
        `,
      args: (arg: any, t: any) => [fcl.arg(userAddress, fcl.t.Address)]
    })
    console.log('is setup check', res)
    return !!res
  }

  async function listNFT(nftId: string, salePrice: string) {
    if (!nftId || !salePrice) return
    const price = salePrice.replace(/,/g, '')
    try {
      setStatus(TransactionStatus.INFLIGHT)
      const txResult = await fcl.send([
        fcl.transaction(LIST_NFT),
        fcl.args([
          fcl.arg(String(nftId), fcl.t.UInt64),
          fcl.arg(String(price), fcl.t.UFix64)
        ]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(txResult.transactionId, 'Listing for sale')
      setTransactionId(txResult.transactionId)
      // await fcl.tx(txResult).onceSealed()
      await waitForSealed(txResult.transactionId)
      setStatus(TransactionStatus.DONE)
      refreshWallets()
    } catch (e) {
      setStatus(TransactionStatus.ERROR)
      console.error(e)
      const errorMessage = (e as Error).toString()
      if (errorMessage.includes('Team Badges cannot be listed for sale.')) {
        setErrorMessage('Team Badges cannot be listed for sale.')
      } else {
        setErrorMessage((e as Error).toString())
      }
      // add papertrail(e)
      throw e
    }
  }

  async function setupMarketplace() {
    try {
      setStatus(TransactionStatus.INFLIGHT)
      const transactionResult = await fcl.send([
        fcl.transaction(SETUP_MARKETPLACE),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(transactionResult.transactionId, 'Setting up marketplace')
      setTransactionId(transactionResult.transactionId)
      // await fcl.tx(transactionId).onceSealed()
      await waitForSealed(transactionResult.transactionId)
      setStatus(TransactionStatus.DONE)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setErrorMessage((e as Error).toString())
      setStatus(TransactionStatus.ERROR)
      throw e
    }
  }

  async function cancelNFTSale(nftId: string) {
    if (!nftId) return
    try {
      setStatus(TransactionStatus.INFLIGHT)
      const transactionResult = await fcl.send([
        fcl.transaction(CANCEL_NFT_SALE),
        fcl.args([fcl.arg(String(nftId), fcl.t.UInt64)]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(transactionResult.transactionId, 'Cancelling Listing')
      setTransactionId(transactionResult.transactionId)
      // await fcl.tx(transactionId).onceSealed()
      await waitForSealed(transactionResult.transactionId)
      setStatus(TransactionStatus.DONE)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setErrorMessage((e as Error).toString())
      setStatus(TransactionStatus.ERROR)
      throw e
    }
  }

  async function cancelAllNFTSale() {
    try {
      setStatus(TransactionStatus.INFLIGHT)
      const transactionResult = await fcl.send([
        fcl.transaction(CANCEL_ALL_NFT_SALE),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(transactionResult.transactionId, 'Cancelling All Listings')
      setTransactionId(transactionResult.transactionId)
      // await fcl.tx(transactionId).onceSealed()
      await waitForSealed(transactionResult.transactionId)
      setStatus(TransactionStatus.DONE)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setErrorMessage((e as Error).toString())
      setStatus(TransactionStatus.ERROR)
      throw e
    }
  }

  async function migrateMarketplaceListings() {
    try {
      setStatus(TransactionStatus.INFLIGHT)
      const transactionResult = await fcl.send([
        fcl.transaction(MIGRATE_MARKETPLACE_LISTINGS_TX),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(transactionResult.transactionId, 'Migrating Listings')
      setTransactionId(transactionResult.transactionId)
      // await fcl.tx(transactionId).onceSealed()
      await waitForSealed(transactionResult.transactionId)
      setStatus(TransactionStatus.DONE)
      refreshWallets()
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setErrorMessage((e as Error).toString())
      setStatus(TransactionStatus.ERROR)
      throw e
    }
  }

  async function purchaseNFT(
    sellerAddress: string,
    nftId: number,
    purchaseAmount: string
  ) {
    if (!sellerAddress || !nftId || !purchaseAmount) return

    const isSellersMarketplaceSetup = await checkIsMarketplaceSetup(
      sellerAddress
    )
    if (!isSellersMarketplaceSetup) {
      openSnackbar(
        'Seller needs to update their account. Please try again later.',
        5000,
        'error'
      )
      return
    }

    try {
      setStatus(TransactionStatus.INFLIGHT)
      const tx = await fcl.send([
        fcl.transaction(PURCHASE_NFT),
        fcl.args([
          fcl.arg(sellerAddress, fcl.t.Address),
          fcl.arg(String(nftId), fcl.t.UInt64),
          fcl.arg(String(parseFloat(purchaseAmount).toFixed(2)), fcl.t.UFix64)
        ]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(tx.transactionId, 'Purchasing in progress')
      setTransactionId(tx.transactionId)
      // await fcl.tx(tx).onceSealed()
      await waitForSealed(tx.transactionId)
      setStatus(TransactionStatus.DONE)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setErrorMessage((e as Error).toString())
      setStatus(TransactionStatus.ERROR)
      throw e
    }
  }

  return {
    listNFT,
    cancelNFTSale,
    cancelAllNFTSale,
    purchaseNFT,
    migrateMarketplaceListings,
    setupMarketplace,
    isMarketplaceSetup,
    TransactionStatus,
    transactionId,
    status,
    errorMessage
  }
}
