export const CHECK_COLLECTION = `
  import AFLNFT from 0xAFLContracts
  import FungibleToken from 0xFungibleToken
  import USDCFlow from 0xUSDCFlow

  access(all) fun main(addr: Address): Bool {
    let nftCollectionRef = getAccount(addr).capabilities.get<&{AFLNFT.AFLNFTCollectionPublic}>(AFLNFT.CollectionPublicPath).check()
    return nftCollectionRef
  }
`
