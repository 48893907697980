export const GET_WUSDC_BALANCE = `
  import FungibleToken from 0xFungibleToken
  import USDCFlow from 0xUSDCFlow

  access(all) fun main(address: Address): UFix64? {
    let account = getAccount(address)
    let vaultRef = account.capabilities.get<&USDCFlow.Vault>(USDCFlow.VaultPublicPath)
        .borrow()

    return vaultRef?.balance
}
`
