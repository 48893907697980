import { useEffect, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { GET_TEMPLATE_BY_ID } from '../flow/scripts/getTemplateById'
import { BUY_PACK } from 'flow/transactions/buyPack.tx'
import { Pack } from 'types'
import {
  serverAuthorization,
  createTransaction,
  precheck,
  sendTxId
} from 'services/api.service'
import { useTransactions } from 'providers/TransactionsProvider'
import { useWallets } from 'providers/WalletsProvider'
import { gaEvents } from 'services/analytics.service'
import { waitForSealed } from 'utils/flow'
import { useSnackbar } from './useSnackbar'

export enum PackStatus {
  LOADING,
  IDLE,
  BUYING,
  DONE,
  DONE_BUY,
  ERROR
}

type PackCache = {
  [key: number]: {
    pack: Pack
    momentIdsInPack: string[]
  }
}

const packCache: PackCache = {}

export default function usePack(id: number) {
  const { openSnackbar } = useSnackbar()

  const [pack, setPack] = useState<Pack>()
  const [momentIdsInPack, setMomentIdsInPack] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState<PackStatus>(PackStatus.IDLE)
  const { addTx } = useTransactions()
  const { flowUser, flowWUSDCBalance, userAuth, refreshWallets } = useWallets()

  const [userHasFunds, setUserHasFunds] = useState(false)

  useEffect(() => {
    if (!flowUser || !pack?.immutableData.price) return
    setUserHasFunds(flowWUSDCBalance > Number(pack?.immutableData.price))
  }, [flowUser, flowWUSDCBalance, pack])

  useEffect(() => {
    if (!id) return
    const getPackMetadata = async () => {
      setStatus(PackStatus.LOADING)
      try {
        if (packCache[id]) {
          const cachedPack = packCache[id]
          setPack(cachedPack.pack)
          setMomentIdsInPack(cachedPack.momentIdsInPack)
          setStatus(PackStatus.DONE)
          return
        }
        const res = await fcl.query({
          cadence: GET_TEMPLATE_BY_ID,
          args: (arg: any, t: any) => [arg(String(id), t.UInt64)]
        })
        setPack(res)
        const _momentIdsInPack = JSON.parse(res.immutableData.nftTemplates).map(
          (moment: any) => moment.id
        )
        setMomentIdsInPack(_momentIdsInPack)
        packCache[id] = {
          pack: res,
          momentIdsInPack: _momentIdsInPack
        }
        setStatus(PackStatus.DONE)
      } catch (e) {
        console.error(e)
        // add papertrail(e)
        setStatus(PackStatus.ERROR)
      }
    }
    getPackMetadata()
  }, [id])

  async function buyPack() {
    if (!flowUser?.addr || !pack) return
    try {
      setStatus(PackStatus.BUYING)

      const { result, combinationId } = await precheck(
        flowUser?.addr,
        pack?.immutableData.price || '0.0',
        String(id),
        false
      )

      const castedIds = result.map((id: any) => {
        if (!id?.serial) {
          return [
            {
              key: 'id',
              value: id.id
            }
          ]
        } else {
          return [
            {
              key: 'id',
              value: id.id
            },
            {
              key: 'serial',
              value: String(id.serial)
            }
          ]
        }
      })

      // Set a timeout to refresh the page after 2 minutes
      const refreshTimeout = setTimeout(() => {
        console.warn(
          'Transaction is taking longer than expected. Refreshing the page.'
        )
        // Refresh the page
        window.location.reload()
      }, 120000) // 1200,000 milliseconds = 2 minutes

      const transactionId = await fcl.send([
        fcl.transaction(BUY_PACK),
        fcl.args([
          fcl.arg(
            castedIds,
            fcl.t.Array(
              fcl.t.Dictionary({ key: fcl.t.String, value: fcl.t.UInt64 })
            )
          ),
          fcl.arg(String(id), fcl.t.UInt64),
          fcl.arg(pack?.immutableData.price, fcl.t.UFix64),
          fcl.arg(flowUser?.addr, fcl.t.Address)
        ]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([serverAuthorization, userAuth]),
        fcl.limit(9999)
      ])
      addTx(transactionId.transactionId, 'Processing')
      await sendTxId(transactionId.transactionId, combinationId)
      // await fcl.tx(transactionId).onceSealed()
      await waitForSealed(transactionId.transactionId)
      clearTimeout(refreshTimeout)
      await createTransaction(flowUser?.addr, transactionId.transactionId)
      openSnackbar('Pack purchase completed!', 5000)
      await refreshWallets()
      setStatus(PackStatus.DONE_BUY)
      gaEvents.buyPack(pack, transactionId)
    } catch (e: any) {
      // calling component is dismounted so we must show snackbar here
      openSnackbar('Error purchasing pack: ' + e?.message, 5000)
      // add papertrail(e)
    }
  }

  async function precheckValidation() {
    if (!id) {
      setErrorMessage('Pack Id not set, please refresh your browser')
      setStatus(PackStatus.ERROR)
      return
    }
    try {
      setStatus(PackStatus.BUYING)
      await precheck(
        flowUser?.addr,
        pack?.immutableData.price || '0.0',
        String(id),
        true
      )
      setStatus(PackStatus.IDLE)
      return true
    } catch (e: any) {
      // add papertrail(e)
      setErrorMessage(
        e?.message || 'Transaction rejected, please check your balance'
      )
      await new Promise((resolve) => setTimeout(resolve, 200))
      setStatus(PackStatus.ERROR)
      console.error(e)
      return false
    }
  }

  return {
    status,
    pack,
    momentIdsInPack,
    buyPack,
    errorMessage,
    userHasFunds,
    precheckValidation
  }
}
