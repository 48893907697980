export const CHECK_WUSDC = `
  import FungibleToken from 0xFungibleToken
  import USDCFlow from 0xUSDCFlow

  access(all) fun main(address: Address): Bool {
      let account = getAccount(address)
      let vault = account.capabilities.get<&USDCFlow.Vault>(USDCFlow.VaultPublicPath)
      return vault.check()
  }
`
