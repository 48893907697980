import { GET_ALL_NFTS_BY_ACCOUNT } from './scripts/getAllNFTsByAccount'
import * as fcl from '@blocto/fcl'

import {
  GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT,
  GET_LISTINGS_DETAILS_BY_ACCOUNT
} from './scripts/getListingsDetailsByAccount'
import { CHECK_COLLECTION } from './scripts/checkCollection.script'
import { CREATE_COLLECTION } from './transactions/createCollection.tx'
import { waitForSealed } from 'utils/flow'
import { GET_TOTAL_BURNS_FOR_TEMPLATE } from './scripts/getTotalBurnsForTemplate.script'
import { GET_WUSDC_BALANCE } from './scripts/getFlowWUSDCBalance.script'
import { CHECK_WUSDC } from './scripts/checkWUSDC.script'

export const getBurntSupply = async (templateId: number): Promise<number> => {
  try {
    const res = await fcl.query({
      cadence: GET_TOTAL_BURNS_FOR_TEMPLATE,
      args: (arg: any, t: any) => [arg(templateId, t.UInt64)]
    })

    return res
  } catch (e) {
    // add papertrail(e)
    console.error(e)
    throw e
  }
}

export const getTotalListedMoments = async (
  address: string
): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_LISTINGS_DETAILS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments = Object.keys(collectionResults).length
    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string })?.stack?.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error(
        'address not found or collection not setup when getting listed moments for account',
        address
      )
      return 0
    } else {
      // add papertrail(e)
      console.error(e)
      throw e
    }
  }
}

export const getLegacyListedMoments = async (
  address: string
): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments = Object.keys(collectionResults).length
    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string })?.stack?.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error('address not found or collection not setup')
      return 0
    } else {
      // add papertrail(e)
      console.error(e)
      throw e
    }
  }
}

export const getTotalNonListedMoments = async (
  address: string
): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  if (!(await collectionUtils.check(address))) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_ALL_NFTS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments = collectionResults.length
    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string }).stack.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error(
        'address not found or collection not setup when getting non listed moments for account',
        address
      )
      return 0
    } else {
      // add papertrail(e)
      console.error(e)
      throw e
    }
  }
}

export const getTotalMoments = async (address: string): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  if (!(await collectionUtils.check(address))) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_ALL_NFTS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const listedCollectionResults = await fcl.query({
      cadence: GET_LISTINGS_DETAILS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments =
      collectionResults.length + Object.keys(listedCollectionResults).length

    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string }).stack.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error(
        'address not found or collection not setup when getting total moments for account',
        address
      )
      return 0
    } else {
      // add papertrail(e)
      console.error(e)
      throw e
    }
  }
}

export const getDuplicatesInExchange = async () => {
  try {
    const tokenData = await fcl.query({
      cadence: `import AFLBurnExchange from 0xAFLContracts
      access(all) fun main(): {UInt64: UInt64} {
          let r: {UInt64: UInt64} = {}
          for id in AFLBurnExchange.getTokenIds() {
              r.insert(key: id, AFLBurnExchange.getTokenId(id: id))
          }
          return r
      }`
    })
    console.log({ tokenData })
    return tokenData
  } catch (e) {
    console.error(e)
    // add papertrail(e)
  }
}

export const getWUSDCBalanceForAccount = async (address: string) => {
  if (!address || !address.startsWith('0x')) return 0
  const isWUSDCSetup = await checkWUSDCAccountSetup(address)
  if (!isWUSDCSetup) return 0
  try {
    const res = await fcl.query({
      cadence: GET_WUSDC_BALANCE,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })
    const balance = parseFloat(res)
    if (isNaN(balance)) {
      return 0
    }
    return balance
  } catch (err) {
    // add papertrail(err)
    console.error(address)
    console.error(address, err)
    throw err
  }
}

// checks active wallet has WUSDC receiver capability setup
export const checkWUSDCAccountSetup = async (
  address: string
): Promise<boolean> => {
  try {
    const res = await fcl.query({
      cadence: CHECK_WUSDC,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })
    return res
  } catch (err) {
    // add papertrail(err)
    console.error(err)
    return false
  }
}

export const collectionUtils = {
  check: async (addr: string) => {
    if (!addr || !addr.startsWith('0x')) return
    try {
      const res = await fcl.query({
        cadence: CHECK_COLLECTION,
        args: (arg: any, t: any) => [arg(addr, t.Address)]
      })
      return res
    } catch (e) {
      console.error(e)
      // add papertrail(e)
    }
  },
  create: async (authFunc?: any) => {
    try {
      const res = await fcl.send([
        fcl.transaction(CREATE_COLLECTION),
        fcl.payer(authFunc),
        fcl.proposer(authFunc),
        fcl.authorizations([authFunc]),
        fcl.limit(9999)
      ])
      await waitForSealed(res.transactionId)
      return res
    } catch (e) {
      console.error(e)
      // add papertrail(e)
    }
  }
}
