export const GET_LISTINGS_DETAILS_BY_ACCOUNT = `
import AFLMarketplaceV2 from 0xAFLContracts

access(all) fun main(addr:Address): AnyStruct {
  let account = getAccount(addr)
  let acctCapability: &AFLMarketplaceV2.SaleCollection? =  account.capabilities.get<&AFLMarketplaceV2.SaleCollection>(AFLMarketplaceV2.SaleCollectionPublicPath)
                            .borrow()

  if acctCapability == nil {
    return []
  } else {
    return acctCapability!.getDetails()
  }
}
`

// This is for the AFLMarketplace contract
export const GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT = `
import AFLMarketplace from 0xAFLContracts

access(all) fun main(addr:Address): AnyStruct {
  let account: &Account = getAccount(addr)

  let acctCapability: &AFLMarketplace.SaleCollection? = account.capabilities.borrow<&AFLMarketplace.SaleCollection>(
    /public/AFLSaleCollection
  )

  if acctCapability == nil {
    return []
  } else {
    return acctCapability!.getDetails()
  }
}
`

export const GET_STUCK_LISTINGS_DETAILS_BY_ACCOUNT = `
import AFLMarketplaceV2 from 0xAFLContracts

access(all) fun main(addr:Address): AnyStruct {
  let account: &Account = getAccount(addr)
  let acctCapability: &{AFLMarketplaceV2.SalePublic}? =  account.capabilities.get<&{AFLMarketplaceV2.SalePublic}>(/public/AFLSaleCollection2).borrow()
  if acctCapability == nil {
    return []
  } else {
    return acctCapability!.getDetails()
  }
}
`
