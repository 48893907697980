import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { Fade, Modal, Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'providers/AuthProvider'
import Backdrop from '@mui/material/Backdrop'
import CustomButton from 'components/Button/CustomButton'
import { Add } from '@mui/icons-material'
import BenefitsBlock from 'components/DataDisplay/BenefitsBlock'
import { Toggle5 } from 'components/StateMod/Toggle'
import { AnimateSharedLayout } from 'framer-motion'
import { useWallets } from 'providers/WalletsProvider'
import blocto from '/assets/Icon/blocto.svg'
import magic from '/assets/Icon/Logo-Magic.svg'
import CollectibleTransferCard from 'components/Card/CollectibleTransferCard'
import Spinner from 'components/Transactions/Spinner'
import FaqSection from 'components/ContentBlock/FaqSection'

export interface ComingSoonModalProps {
  isMagic: boolean
  setupWalletModalOpen: boolean
  setSetupWalletModalOpen: Dispatch<SetStateAction<boolean>>
}

const SetupWalletModal = ({
  isMagic,
  setupWalletModalOpen,
  setSetupWalletModalOpen
}: ComingSoonModalProps) => {
  const navigate = useNavigate()
  const {
    isConnected,
    connectMagic,
    connectBlocto,
    disconnect,
    registeredWallets,
    connectedWalletAddress,
    magicMomentCount,
    bloctoMomentCount,
    usingMagic,
    magicWalletAddress,
    magicWalletWUSDCBalance,
    bloctoWalletAddress,
    bloctoWalletWUSDCBalance,
    magicForSaleMomentCount,
    magicNotForSaleMomentCount,
    bloctoForSaleMomentCount,
    bloctoNotForSaleMomentCount
  } = useWallets()
  const [activeStep, setActiveStep] = React.useState(0)
  const [creatingWallet, setCreatingWallet] = React.useState(false)
  const _isConnected = isMagic
    ? usingMagic && isConnected
    : !usingMagic && isConnected
  const handleClose = () => {
    setSetupWalletModalOpen(false)
  }
  const handleConnect = () => {
    if (isMagic) {
      setCreatingWallet(true)
      handleMagicConnect()
    } else {
      handleBloctoConnect()
    }
    handleClose()
  }

  const handleMagicConnect = () => {
    if (!_isConnected) {
      connectMagic().then(() => {
        setActiveStep(1)
        setCreatingWallet(false)
      })
    } else {
      disconnect()
    }
  }

  const handleBloctoConnect = () => {
    if (!_isConnected) {
      connectBlocto().then(() => {
        setActiveStep(1)
      })
    } else {
      disconnect()
    }
  }

  const activeMagicWallet = [
    {
      name: 'Magic',
      icon: magic,
      address: magicWalletAddress,
      balance: magicWalletWUSDCBalance,
      collectibles: magicMomentCount,
      forSaleCollectibles: magicForSaleMomentCount,
      notForSaleCollectibles: magicNotForSaleMomentCount
    }
  ]
  const activeBloctoWallet = [
    {
      name: 'Blocto',
      icon: blocto,
      address: bloctoWalletAddress,
      balance: bloctoWalletWUSDCBalance,
      collectibles: bloctoMomentCount,
      forSaleCollectibles: bloctoForSaleMomentCount,
      notForSaleCollectibles: bloctoNotForSaleMomentCount
    }
  ]

  const AddWallet = () => {
    return (
      <div className="flex flex-col gap-6 justify-center items-center w-full max-w-[600px]">
        <div className="pt-4 text-center text-white md:pt-0">
          <p className="pb-2 font-podium49 text-4xl uppercase md:text-5xl">
            <span className="hidden md:inline-block">Add</span>
            {isMagic ? ' Magic wallet' : ' Blocto wallet'}
          </p>
          <p className="text-xl md:text-2xl">
            {isMagic
              ? 'A new wallet option for faster login and transactions'
              : 'A flexible wallet choice for mint transactions'}
          </p>
        </div>
        <div className="flex flex-col gap-3 py-1">
          <BenefitsBlock
            content={
              isMagic
                ? 'Automatically connects with AFL iD login when your collection is in Magic.'
                : 'App available on iOS & Google Play'
            }
          />
          <BenefitsBlock
            content={
              isMagic
                ? 'No extra email codes to enter to connect'
                : 'Cross-chain smart contract wallet'
            }
          />
          <BenefitsBlock
            content={
              isMagic
                ? 'Easily transfer your collection to Magic'
                : 'Manage gas fees with Blocto points'
            }
          />
        </div>
        <AnimateSharedLayout>
          <Toggle5 title="Good to know" width="100%">
            {isMagic ? (
              <div className="!pt-[14px] answer">
                <p className="mb-4 text-xs text-grey3">
                  When you create an account a ‘Magic’ digital wallet will be
                  created for you to use on AFL Mint. This will be created using
                  the email address provided with your AFL iD and does not
                  required a separate password or login to use. If you have an
                  existing Magic wallet from another project it is not possible
                  to import your previous Magic wallet at this time. A digital
                  wallet holds proof of digital tokens like cryptocurrencies and
                  digital collectibles and is required to participate in the AFL
                  Mint program. AFL Mint uses{' '}
                  <a
                    href="https://magic.link/"
                    target="_blank"
                    rel="noreferrer"
                    className="underline hover:no-underline"
                  >
                    Magic wallet
                  </a>{' '}
                  as the primary wallet. After signing up other wallet options
                  are available.
                </p>
                <p className="text-xs italic text-grey3">
                  Australian Football League (<strong>AFL</strong>) does not
                  provide crypto or digital wallets (
                  <strong>Digital Wallet</strong>) or any services in respect
                  of, or access to, any Digital Wallets. Any Digital Wallet
                  which can be accessed from any AFL website or otherwise used
                  in connection with any officially licensed AFL digital
                  collectibles is provided by a third party. Before using or
                  accessing any Digital Wallet or agreeing to deposit or store
                  any officially licensed AFL Digital Collectibles into a
                  Digital Wallet, you should read and consider any terms and
                  conditions which apply to the use of, and access to, the
                  Digital Wallet. To the extent permitted by law, AFL is not
                  responsible or liable for any loss or damage which may be
                  suffered or incurred by you or any other person in respect of
                  your or that person’s use of, or access to, any Digital
                  Wallet, including any loss or damage to any officially
                  licensed AFL Digital Collectible as a result of using the
                  Digital Wallet or depositing or storing the officially
                  licensed AFL Digital Collectible in the Digital Wallet.
                </p>
              </div>
            ) : (
              <div className="!pt-[14px] answer">
                <p className="mb-4 text-sm text-grey3">
                  Blocto is a digital wallet. A digital wallet holds proof of
                  digital tokens like cryptocurrencies and NFTs and is required
                  to participate in the AFL Mint program.
                </p>
                <p className="text-xs italic text-grey3">
                  Australian Football League (<strong>AFL</strong>) does not
                  provide crypto or digital wallets (
                  <strong>Digital Wallet</strong>) or any services in respect
                  of, or access to, any Digital Wallets. Any Digital Wallet
                  which can be accessed from any AFL website or otherwise used
                  in connection with any officially licensed AFL digital
                  collectibles is provided by a third party. Before using or
                  accessing any Digital Wallet or agreeing to deposit or store
                  any officially licensed AFL Digital Collectibles into a
                  Digital Wallet, you should read and consider any terms and
                  conditions which apply to the use of, and access to, the
                  Digital Wallet. To the extent permitted by law, AFL is not
                  responsible or liable for any loss or damage which may be
                  suffered or incurred by you or any other person in respect of
                  your or that person’s use of, or access to, any Digital
                  Wallet, including any loss or damage to any officially
                  licensed AFL Digital Collectible as a result of using the
                  Digital Wallet or depositing or storing the officially
                  licensed AFL Digital Collectible in the Digital Wallet.
                </p>
              </div>
            )}
          </Toggle5>
        </AnimateSharedLayout>
        <div className="flex flex-col gap-4 mt-[-10px] max-w-[400px]">
          <CustomButton
            label={isMagic ? 'Add Magic wallet' : 'Add Blocto wallet'}
            model="primary"
            variant="contained"
            onClick={handleConnect}
            disabled={creatingWallet}
          />
          {creatingWallet && (
            <div className="flex text-white flex-col gap-6 items-center">
              <Spinner />
              Creating wallet in process, please wait
            </div>
          )}
          <CustomButton
            label="Cancel"
            model="secondary"
            variant="contained"
            onClick={() => handleClose()}
          />
          <div>
            <p className="text-xs text-center text-grey3">
              By clicking ‘Create account’ you agree to create a ‘Magic’ digital
              wallet as part of your AFL Mint account to securely store any
              collectibles and credits you choose to use on AFL Mint. See Magic{' '}
              <a
                href="https://magic.link/legal/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                terms of service
              </a>{' '}
              and{' '}
              <a
                href="https://magic.link/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                privacy policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    )
  }

  const WalletAdded = () => {
    return (
      <div className="flex flex-col gap-6 justify-center items-center w-full max-w-[600px]">
        <div className="flex flex-col gap-2 justify-center items-center pt-4 w-full max-w-[600px] text-center text-white md:pt-0">
          <p className="font-podium49 text-4xl uppercase md:text-5xl">
            Wallet added
          </p>
          <p className="md:text-lg">
            You can now transfer your collection to your new wallet
          </p>
        </div>
        <div className="flex gap-3 w-full max-w-[400px] text-white">
          <CollectibleTransferCard
            wallets={usingMagic ? activeMagicWallet : activeBloctoWallet}
          />
        </div>
        <div className="flex flex-col gap-4 justify-center items-center w-full max-w-[400px]">
          <Link
            to="/account?section=wallet-transfer"
            className="w-full underline hover:no-underline"
          >
            <CustomButton
              label="Continue to transfer"
              model="primary"
              variant="contained"
              hasEndIcon={true}
              className="w-full"
            />
          </Link>
          <CustomButton
            label="Maybe later"
            model="secondary"
            variant="contained"
            className="w-full"
            onClick={() => setActiveStep(2)}
          />
        </div>
        <div className="w-full text-center text-grey3">
          Manage your wallets at any time from your{' '}
          <Link
            to="/account?section=manage-wallets"
            className="underline hover:no-underline"
          >
            account settings
          </Link>
        </div>
      </div>
    )
  }

  const LaterConfirmation = () => {
    return (
      <div className="flex flex-col gap-6 justify-center items-center w-full max-w-[600px]">
        <div className="flex flex-col gap-2 justify-center items-center pt-4 w-full max-w-[600px] text-center text-white md:pt-0">
          <p className="font-podium49 text-4xl uppercase md:text-5xl">Got it</p>
          <p>
            You can manage your wallets and transfer your collection from your{' '}
            <Link
              to="/account?section=manage-wallets"
              className="underline hover:no-underline"
            >
              account settings
            </Link>{' '}
            at any time.
          </p>
        </div>
        <Link to="/account" className="flex flex-col-reverse gap-4 md:flex-col">
          <CustomButton
            label="Back to account"
            model="primary"
            variant="contained"
          />
        </Link>
      </div>
    )
  }

  return (
    <Modal
      open={setupWalletModalOpen}
      onClose={setSetupWalletModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: 'transparent'
        }
      }}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'transparent'
        }
      }}
      className="overflow-auto bg-black p-10 md:p-20 h-full"
    >
      <div className="flex absolute left-1/2 flex-col items-center px-4 w-full translate-x-[-50%]">
        <div
          className="flex absolute top-0 right-6 justify-end items-end w-full cursor-pointer md:top-0 md:right-10"
          onClick={() => handleClose()}
        >
          <Add
            style={{
              transform: `rotate(45deg)`,
              color: '#ffffff',
              fontSize: '40px'
            }}
          />
        </div>
        <div className="flex flex-col justify-center items-center p-4 w-full max-w-[900px] bg-card sm:p-8 lg:p-12">
          {activeStep === 0 && <AddWallet />}
          {activeStep === 1 && <WalletAdded />}
          {activeStep === 2 && <LaterConfirmation />}
        </div>
        <div className="text-white w-full">
          <FaqSection
            header={isMagic ? 'About Magic' : 'About Blocto'}
            faqName={isMagic ? 'magicWallet' : 'bloctoWallet'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SetupWalletModal
