export const PURCHASE_NFT = `
import AFLNFT from 0xAFLContracts
import FungibleToken from 0xFungibleToken;
import USDCFlow from 0xUSDCFlow;
import AFLMarketplaceV2 from 0xAFLContracts

transaction(sellerAddress: Address, tokenID: UInt64, purchaseAmount: UFix64) {
    let collectionCap: Capability<&AFLNFT.Collection>
    let vaultCap: Capability<&USDCFlow.Vault>
    let temporaryVault: @{FungibleToken.Vault}

    prepare(acct: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability, FungibleToken.Withdraw) &Account) {
        self.collectionCap = acct.capabilities.get<&AFLNFT.Collection>(AFLNFT.CollectionPublicPath)
        self.vaultCap = acct.capabilities.get<&USDCFlow.Vault>(USDCFlow.ReceiverPublicPath)

        let vaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &USDCFlow.Vault>(from: USDCFlow.VaultStoragePath) ?? panic("Could not borrow owners Fiat Vault reference")

        self.temporaryVault <- vaultRef.withdraw(amount: purchaseAmount)
    }

    execute {
        let seller = getAccount(sellerAddress)

        let marketplace = seller.capabilities.get<&AFLMarketplaceV2.SaleCollection>(/public/AFLMarketplaceSaleCollectionV2).borrow() ?? panic("Could not borrow marketplace reference")

        marketplace.purchase(tokenID: tokenID, recipientCap: self.collectionCap, buyTokens: <- self.temporaryVault)
    }
}
`
