import { getGenesisBallTemplateIds } from 'services/genesis_ball.api.service'

const packIdsTestnet: string[] = [
  '43',
  '29'
  // '1678',
  // '1679'
]

const packIdsMainnet: string[] = [
  '3714',
  '7700',
  '7701',
  '10596',
  '10597',
  '10598',
  '12343',
  '12344',
  '14231',
  '15466',
  '16622',
  '17221',
  '17715',
  '18186',
  '18592',
  '19045',
  '19310',
  '19311',
  '19951',
  '20428',
  '21029',
  '21603',
  '22080',
  '22517',
  '22518',
  '23162',
  '24977',
  '25682',
  '26480',
  '27290',
  '27908',
  '29033',
  '28341',
  '29824',
  '30704',
  '31525'
]

const collectionIdsTestnet: { [key: string]: number[] } = {
  All: [], // auto populated below

  Champs: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27],

  'Ripper Skipper': [],
  'The Decider': [],
  'Ripper Grabs': [],
  'Ripper Goals': [],
  'Starter Pack': [],
  'Starter Pack #1': [],
  Relentless: [],
  Throwbacks: [],
  'Genesis Ball': [],
  'Mint Moments': [],
  'Mint Moments - 2023 - R1-2': [],
  'Mint Moments - 2023 - R3-4': [],
  'Mint Moments - 2023 - R5-6': [],
  'Mint Moments - 2023 - R7-8': [],
  'Mint Moments - 2023 - R9-10': [],
  'Mint Moments - 2023 - R11-12': [],
  'Mint Moments - 2023 - R13-14': [],
  'Mint Moments - 2023 - R15-16': [],
  'Mint Moments - 2023 - R17-18': [],
  'Mint Moments - 2023 - R19-20': [],
  'Team Badges 2023': [],
  'Mint Moments - 2023 - R21-22': [],
  'Mint Moments - 2023 - R23-24': [],
  'Ripper Skipper 2023': [],
  'The Finals': [],
  'The Finals - 2023 - WEEK1': [],
  'The Finals - 2023 - WEEK2': [],
  'The Finals - 2023 - WEEK3': [],
  'AFLW Mixtape': [],
  'Mint Rookies': [],
  'Starter Pack 2024': [],
  'Mint Moments 2024': [],
  'Mint Moments - 2024 - R0': [],
  'Mint Moments - 2024 - R1-5': [],
  'Mint Moments - 2024 - R6-10': [],
  'Throwbacks 2000s': [],
  'Mint Moments - 2024 - R16-20': []
}

const collectionIdsMainnet: { [key: string]: number[] } = {
  All: [], // auto populated below
  'Ripper Skipper': [
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
    70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105,
    106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
    121, 122, 123, 124, 125, 126, 127, 128
  ],
  'The Decider': [
    7634, 7635, 7636, 7637, 7638, 7639, 7640, 7641, 7642, 7643, 7644, 7645,
    7646, 7647, 7648, 7649, 7650, 7651, 7652, 7653, 7654, 7655, 7656, 7657,
    7658, 7659, 7660, 7661, 7662, 7663, 7664, 7665, 7666, 7667, 7668, 7669,
    7670, 7671, 7672, 7673, 7674, 7675, 7676, 7677, 7678, 7679, 7680, 7681,
    7682, 7683, 7684, 7685, 7686, 7687, 7688, 7689, 7690, 7691, 7692, 7693, 7694
  ],
  'Ripper Grabs': [
    10546, 10547, 10548, 10549, 10550, 10551, 10552, 10553, 10554, 10555, 10556,
    10557, 10558, 10559, 10560, 10561, 10562, 10563, 10564, 10565, 10566, 10567,
    10568, 10569, 10570, 10571, 10572, 10573, 10574, 10575, 10576, 10577, 10578,
    10579, 10580, 10581, 10582, 10583, 10584, 10585, 10586, 10587, 10588, 10589,
    10590, 10591, 10592, 10593, 10594, 10595
  ],
  'Ripper Goals': [
    12313, 12314, 12315, 12316, 12317, 12318, 12319, 12320, 12321, 12322, 12323,
    12324, 12325, 12326, 12327, 12328, 12329, 12330, 12331, 12332, 12333, 12334,
    12335, 12336, 12337, 12338, 12339, 12340, 12341, 12342
  ],
  'Starter Pack': [
    14201, 14202, 14203, 14204, 14205, 14206, 14207, 14208, 14209, 14210, 14211,
    14212, 14213, 14214, 14215, 14216, 14217, 14218, 14219, 14220, 14221, 14222,
    14223, 14224, 14225, 14226, 14227, 14228, 14229, 14230
  ],
  'Starter Pack #1': [
    14201, 14202, 14203, 14204, 14205, 14206, 14207, 14208, 14209, 14210, 14211,
    14212, 14213, 14214, 14215, 14216, 14217, 14218, 14219, 14220, 14221, 14222,
    14223, 14224, 14225, 14226, 14227, 14228, 14229, 14230
  ],
  Relentless: [
    15444, 15445, 15446, 15447, 15448, 15449, 15450, 15451, 15452, 15453, 15454,
    15455, 15456, 15457, 15458, 15459, 15460, 15461, 15462, 15463, 15464, 15465
  ],
  Throwbacks: [
    19275, 19276, 19277, 19278, 19279, 19280, 19281, 19282, 19283, 19284, 19285,
    19286, 19287, 19288, 19289, 19290
  ],
  'Genesis Ball': [], // auto populated below
  'Mint Moments': [
    16613, 16612, 16616, 16614, 16618, 16615, 16619, 16620, 16617, 16621, 17213,
    17211, 17218, 17212, 17214, 17219, 17216, 17215, 17220, 17217, 17705, 17706,
    17707, 17708, 17709, 17710, 17711, 17712, 17713, 17714, 18176, 18177, 18178,
    18179, 18180, 18181, 18182, 18183, 18184, 18185, 18582, 18583, 18584, 18585,
    18586, 18587, 18588, 18589, 18590, 18591, 19029, 19030, 19031, 19032, 19033,
    19034, 19035, 19036, 19037, 19038, 19941, 19942, 19943, 19944, 19945, 19946,
    19947, 19948, 19949, 19950, 20418, 20419, 20420, 20421, 20422, 20423, 20424,
    20425, 20426, 20427, 21019, 21020, 21021, 21022, 21023, 21024, 21025, 21026,
    21027, 21028, 21590, 21591, 21592, 21593, 21594, 21595, 21596, 21597, 21598,
    21599, 22070, 22071, 22072, 22073, 22074, 22075, 22076, 22077, 22078, 22079,
    23147, 23148, 23149, 23150, 23151, 23152, 23153, 23154, 23155, 23156
  ],
  'Mint Moments - 2023 - R1-2': [
    16613, 16612, 16616, 16614, 16618, 16615, 16619, 16620, 16617, 16621
  ],
  'Mint Moments - 2023 - R3-4': [
    17213, 17211, 17218, 17212, 17214, 17219, 17216, 17215, 17220, 17217
  ],
  'Mint Moments - 2023 - R5-6': [
    17705, 17706, 17707, 17708, 17709, 17710, 17711, 17712, 17713, 17714
  ],
  'Mint Moments - 2023 - R7-8': [
    18176, 18177, 18178, 18179, 18180, 18181, 18182, 18183, 18184, 18185
  ],
  'Mint Moments - 2023 - R9-10': [
    18582, 18583, 18584, 18585, 18586, 18587, 18588, 18589, 18590, 18591
  ],
  'Mint Moments - 2023 - R11-12': [
    19029, 19030, 19031, 19032, 19033, 19034, 19035, 19036, 19037, 19038
  ],
  'Mint Moments - 2023 - R13-14': [
    19941, 19942, 19943, 19944, 19945, 19946, 19947, 19948, 19949, 19950
  ],
  'Mint Moments - 2023 - R15-16': [
    20418, 20419, 20420, 20421, 20422, 20423, 20424, 20425, 20426, 20427
  ],
  'Mint Moments - 2023 - R17-18': [
    21019, 21020, 21021, 21022, 21023, 21024, 21025, 21026, 21027, 21028
  ],
  'Mint Moments - 2023 - R19-20': [
    21590, 21591, 21592, 21593, 21594, 21595, 21596, 21597, 21598, 21599
  ],
  'Mint Moments - 2023 - R21-22': [
    22070, 22071, 22072, 22073, 22074, 22075, 22076, 22077, 22078, 22079
  ],
  'Mint Moments - 2023 - R23-24': [
    23147, 23148, 23149, 23150, 23151, 23152, 23153, 23154, 23155, 23156
  ],
  'Team Badges 2023': [
    22436, 22437, 22438, 22439, 22440, 22441, 22442, 22443, 22444, 22445, 22446,
    22447, 22448, 22449, 22450, 22451, 22452, 22453
  ],
  'Ripper Skipper 2023': [
    22485, 22486, 22487, 22488, 22489, 22490, 22491, 22492, 22493, 22494, 22495,
    22496, 22497, 22498, 22499, 22500, 22501, 22502, 22503, 22504, 22505, 22506,
    22507, 22508
  ],
  'The Finals': [
    24945, 24946, 24947, 24948, 24949, 24950, 25675, 25676, 25677, 25678, 25679,
    25680, 26469, 26470, 26471, 26472, 26473, 26474
  ],
  'The Finals - 2023 - WEEK1': [24945, 24946, 24947, 24948, 24949, 24950],
  'The Finals - 2023 - WEEK2': [25675, 25676, 25677, 25678, 25679, 25680],
  'The Finals - 2023 - WEEK3': [26469, 26470, 26471, 26472, 26473, 26474],
  Champs: [
    27253, 27254, 27255, 27256, 27257, 27258, 27259, 27260, 27261, 27262, 27263,
    27264
  ],
  'AFLW Mixtape': [
    27893, 27894, 27895, 27896, 27897, 27898, 27899, 27900, 27901, 27902, 27903,
    27904, 27905, 27906, 27907
  ],
  'Mint Rookies': [
    28321, 28322, 28323, 28324, 28325, 28326, 28327, 28328, 28329, 28330, 28331,
    28332, 28333, 28334, 28335, 28336, 28337, 28338, 28339, 28340
  ],
  'Starter Pack 2024': [
    28977, 28978, 28979, 28980, 28981, 28982, 28983, 28984, 28985, 28986, 28987,
    28988, 28989, 28990, 28991, 28992, 28993, 28994
  ],
  'Mint Moments 2024': [
    29028, 29029, 29030, 29031, 29032, 29809, 29810, 29811, 29812, 29813, 29814,
    29815, 29816, 29817, 29818, 29819, 29820, 29821, 29822, 29823, 30689, 30690,
    30691, 30692, 30693, 30694, 30695, 30696, 30697, 30698, 30699, 30700, 30701,
    30702, 30703, 31509, 31510, 31511, 31512, 31513, 31514, 31515, 31516, 31517,
    31518, 31519, 31520, 31521, 31522, 31523, 32988, 32989, 32990, 32991, 32992,
    32993, 32994, 32995, 32996, 32997, 32998, 32999, 33000, 33001, 33002
  ],
  'Mint Moments - 2024 - R0': [29028, 29029, 29030, 29031, 29032],
  'Mint Moments - 2024 - R1-5': [
    29809, 29810, 29811, 29812, 29813, 29814, 29815, 29816, 29817, 29818, 29819,
    29820, 29821, 29822, 29823
  ],
  'Mint Moments - 2024 - R6-10': [
    30689, 30690, 30691, 30692, 30693, 30694, 30695, 30696, 30697, 30698, 30699,
    30700, 30701, 30702, 30703, 33979, 33980
  ],
  'Mint Moments - 2024 - R11-15': [
    31509, 31510, 31511, 31512, 31513, 31514, 31515, 31516, 31517, 31518, 31519,
    31520, 31521, 31522, 31523
  ],
  'Reward Moments': [
    30294, 30295, 30296, 30657, 30658, 31813, 31814, 31815, 33082, 33083
  ],
  'Throwbacks 2000s': [
    32185, 32186, 32187, 32188, 32189, 32190, 32191, 32192, 32193, 32194, 32195,
    32196, 32197, 32198, 32199, 32200
  ],
  'Mint Moments - 2024 - R16-20': [
    32988, 32989, 32990, 32991, 32992, 32993, 32994, 32995, 32996, 32997, 32998,
    32999, 33000, 33001, 33002
  ],
  'Ripper Skipper 2024': [
    33462, 33463, 33464, 33465, 33466, 33467, 33468, 33469, 33470, 33471, 33472,
    33473, 33474, 33475, 33476, 33477, 33478, 33479, 33480, 33481, 33482, 33484
  ],
  'Ripper Skipper 2024 Signed': [
    33483, 33485, 33486, 33487, 33488, 33489, 33490, 33491, 33492, 33493, 33494,
    33495, 33496, 33497, 33498, 33499, 33500, 33501, 33502, 33503, 33504, 33505
  ]
}

async function populateGenesisBallTemplateIds() {
  try {
    const ids = (await getGenesisBallTemplateIds()).map((id: string) =>
      parseInt(id)
    )
    // 18096 = serial 2011 + was minted manually
    ids.push(18096)
    collectionIdsMainnet['Genesis Ball'] = ids
  } catch (e) {
    // add papertrail
    console.error('Failed to get Genesis Ball template IDs', e)
  }
  return [18096, ...collectionIdsMainnet['Genesis Ball']] || []
}
populateGenesisBallTemplateIds()

// set the all property to be all the template ids
collectionIdsTestnet['All'] = Object.values(collectionIdsTestnet)
  .filter(Array.isArray)
  .flatMap((arr) => arr)
  .filter((id) => !collectionIdsTestnet['Team Badges 2023'].includes(id))

collectionIdsMainnet.All = Object.values(collectionIdsMainnet)
  .filter(Array.isArray)
  .flatMap((arr) => arr)
  .filter((id) => !collectionIdsMainnet['Team Badges 2023'].includes(id))

export const isMainnet: boolean =
  import.meta.env.VITE_ENVIRONMENT === 'production'

export const collectionIds = isMainnet
  ? collectionIdsMainnet
  : collectionIdsTestnet

export const packIds = isMainnet ? packIdsMainnet : packIdsTestnet

export const allTemplateIds = isMainnet
  ? [...collectionIdsMainnet['All'], ...collectionIdsMainnet['Genesis Ball']]
  : [...collectionIdsTestnet['All'], ...collectionIdsTestnet['Genesis Ball']]

export const isTemplateIdGenesisBall = (templateId: number): boolean => {
  return collectionIds['Genesis Ball'].includes(templateId)
}

const _allMomentTemplates = isMainnet
  ? new Set([...collectionIdsMainnet['All']])
  : new Set([...collectionIdsTestnet['All']])

export const allMomentTemplates = [..._allMomentTemplates].map((id) =>
  id.toString()
)
export const totalMomentTemplates = _allMomentTemplates.size

export const allButRookies = allMomentTemplates.filter(
  (id) => !collectionIds['Mint Rookies'].includes(parseInt(id))
)

collectionIds['AllButRookies'] = allButRookies.map((id) => parseInt(id))

const keysToRemove = new Set([
  'All',
  'Genesis Ball',
  'Starter Pack #1',
  'Mint Moments - 2023 - R1-2',
  'Mint Moments - 2023 - R3-4',
  'Mint Moments - 2023 - R5-6',
  'Mint Moments - 2023 - R7-8',
  'Mint Moments - 2023 - R9-10',
  'Mint Moments - 2023 - R11-12',
  'Mint Moments - 2023 - R13-14',
  'Mint Moments - 2023 - R15-16',
  'Mint Moments - 2023 - R17-18',
  'Mint Moments - 2023 - R19-20',
  'Mint Moments - 2023 - R21-22',
  'Mint Moments - 2023 - R23-24',
  'Team Badges 2023',
  'The Finals - 2023 - WEEK1',
  'The Finals - 2023 - WEEK2',
  'The Finals - 2023 - WEEK3',
  'Mint Moments - 2024 - R0',
  'Reward Moments',
  'Mint Moments - 2024 - R1-5',
  'Mint Moments - 2024 - R6-10',
  'Mint Moments - 2024 - R11-15',
  'Mint Moments - 2024 - R16-20',
  'AllButRookies',
  'Test Moments'
])

export const filteredCollectionIds = Object.fromEntries(
  Object.entries(collectionIds).filter(([key]) => !keysToRemove.has(key))
)
