import * as fcl from '@onflow/fcl'

export const subToTxStatus = (
  txId: string,
  retryLimit = 3,
  timeout = 60000 // 60 seconds
) => {
  return new Promise<any>((resolve, reject) => {
    let retryCount = 0
    const handleStatus = (value: any, err: any) => {
      if (err) {
        console.error('ERR: ', err)
        // add papertrail (err, { extra: { txId, retryLimit } })
        if (retryCount < retryLimit) {
          retryCount++
          console.log(`Retrying ${txId} (${retryCount}/${retryLimit})`)
          subToTxStatus(txId, retryLimit, timeout).then(resolve).catch(reject)
        } else {
          reject(err)
        }
      } else {
        if (value.statusString === 'SEALED' && !value.errorMessage) {
          resolve(value)
        } else if (value.errorMessage) {
          reject(
            new Error(
              `Transaction ${txId} failed with error: ${value.errorMessage}`
            )
          )
        }
      }
    }
    fcl.tx(txId).subscribe(handleStatus)
    setTimeout(() => {
      reject(new Error(`Transaction ${txId} timed out after ${timeout}ms`))
    }, timeout)
  })
}

export const waitForSealed = async (txId: string) => {
  try {
    const status = await subToTxStatus(txId, 3, 60000)
    if (status.statusString !== 'SEALED') {
      throw new Error(
        `Transaction ${txId} failed with status: ${status.statusString}`
      )
    }
  } catch (error) {
    // add papertrail (error, { extra: { txId } })
    throw error
  }
}
