export const MIGRATE_MARKETPLACE_LISTINGS_TX = `
import AFLNFT from 0xAFLContracts
import FungibleToken from 0xFungibleToken
import MetadataViews from 0xMetadataViews
import USDCFlow from 0xUSDCFlow
import AFLMarketplace from 0xAFLContracts
import AFLMarketplaceV2 from 0xAFLContracts

transaction(){
  let oldMarketplaceSaleCollectionRef: auth(AFLMarketplace.Owner) &AFLMarketplace.SaleCollection
  let truMarketplaceSaleCollectionRef: auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection
  let newMarketplaceSaleCollectionRef: auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection?

  prepare(account: auth(Storage, Capabilities, BorrowValue, AFLMarketplaceV2.Owner) &Account){
    let oldMarketplaceCap: Capability<&AFLMarketplace.SaleCollection> = account.capabilities.get<&AFLMarketplace.SaleCollection>(/public/AFLSaleCollection)
    let newMarketplaceCap: Capability<&AFLMarketplaceV2.SaleCollection> = account.capabilities.get<&AFLMarketplaceV2.SaleCollection>(/public/AFLMarketplaceSaleCollection2)
    let truMarketplaceCap: Capability<&AFLMarketplaceV2.SaleCollection> = account.capabilities.get<&AFLMarketplaceV2.SaleCollection>(/public/AFLMarketplaceSaleCollectionV2)

    if !newMarketplaceCap.check() {
      let usdcFlowVaultRef = account.storage.borrow<&USDCFlow.Vault>(from: USDCFlow.VaultStoragePath)

      if (usdcFlowVaultRef == nil) {
        // Create a new USDCFlow Vault and put it in storage
        account.storage.save(
            <-USDCFlow.createEmptyVault(vaultType: Type<@USDCFlow.Vault>()),
            to: USDCFlow.VaultStoragePath
        )
      }

      let wallet = account.capabilities.get<&USDCFlow.Vault>(USDCFlow.ReceiverPublicPath)

      if (!wallet.check()) {
        let receiverCap = account.capabilities.storage.issue<&USDCFlow.Vault>(
          USDCFlow.VaultStoragePath
        )
        account.capabilities.unpublish(USDCFlow.ReceiverPublicPath)
        account.capabilities.unpublish(USDCFlow.VaultPublicPath)
        account.capabilities.publish(receiverCap, at: USDCFlow.ReceiverPublicPath)
        account.capabilities.publish(receiverCap, at: USDCFlow.VaultPublicPath)
      }


      if account.storage.borrow<&AFLMarketplaceV2.SaleCollection>(from: /storage/AFLSaleCollectionV2) == nil {
        let sale <- AFLMarketplaceV2.createSaleCollection(ownerVault: wallet)
        account.storage.save<@AFLMarketplaceV2.SaleCollection>(<-sale , to: /storage/AFLSaleCollectionV2)
      }

      let marketSalePublicCap: Capability<&AFLMarketplaceV2.SaleCollection> = account.capabilities.storage.issue<&AFLMarketplaceV2.SaleCollection>(/storage/AFLSaleCollectionV2)
      account.capabilities.unpublish(/public/AFLMarketplaceSaleCollection2)
      account.capabilities.unpublish(AFLMarketplaceV2.SaleCollectionPublicPath)
      account.capabilities.publish(marketSalePublicCap, at: AFLMarketplaceV2.SaleCollectionPublicPath)

    }

    self.oldMarketplaceSaleCollectionRef = account.storage.borrow<auth(AFLMarketplace.Owner) &AFLMarketplace.SaleCollection>(from: /storage/AFLSaleCollection) ?? panic("could not borrow AFLMarketplace collection")
    self.newMarketplaceSaleCollectionRef = account.storage.borrow<auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection>(from: /storage/AFLSaleCollection2)
    self.truMarketplaceSaleCollectionRef = account.storage.borrow<auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection>(from: /storage/AFLSaleCollectionV2) ?? panic("could not borrow AFLMarketplaceV2 collection")
  }
  execute {

    let TRANSFER_LIMIT = 250
    // migrate stuck listings
    if (self.newMarketplaceSaleCollectionRef != nil) {
        let newIds = self.newMarketplaceSaleCollectionRef?.getIDs()!.slice(from: 0, upTo: self.newMarketplaceSaleCollectionRef?.getIDs()!.length < TRANSFER_LIMIT ? self.newMarketplaceSaleCollectionRef?.getIDs()!.length : TRANSFER_LIMIT);
        for tokenID in newIds {
            let price: UFix64 = self.newMarketplaceSaleCollectionRef?.getPrice(tokenID: tokenID)!!
            let token: @AFLNFT.NFT <- self.newMarketplaceSaleCollectionRef?.withdraw(tokenID: tokenID)!
            self.truMarketplaceSaleCollectionRef.listForSale(token: <- token, price: price)
        }
    }

    // migrate AFLMarketplace listings (precadence1.0)
    let oldsIds = self.oldMarketplaceSaleCollectionRef.getIDs().slice(from: 0, upTo: self.oldMarketplaceSaleCollectionRef.getIDs().length < TRANSFER_LIMIT ? self.oldMarketplaceSaleCollectionRef.getIDs().length : TRANSFER_LIMIT);
    for tokenID in oldsIds {
        let price: UFix64 = self.oldMarketplaceSaleCollectionRef.getPrice(tokenID: tokenID)!
        let token: @AFLNFT.NFT <- self.oldMarketplaceSaleCollectionRef.withdraw(tokenID: tokenID)
        self.truMarketplaceSaleCollectionRef.listForSale(token: <- token, price: price)
    }
  }
}`
