export const UPGRADE_NFT = `
import NonFungibleToken from 0xNFT
import AFLNFT from 0xAFLContracts
import AFLUpgradeExchange from 0xAFLContracts

transaction(upgradeIds: [UInt64], burnIds: [UInt64]) {
    let usersCollectionRef: auth(NonFungibleToken.Withdraw) &AFLNFT.Collection

    prepare(signer: auth(BorrowValue, NonFungibleToken.Withdraw) &Account) {
        self.usersCollectionRef = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &AFLNFT.Collection>(from: /storage/AFLNFTCollection)
            ?? panic("Account does not store an AFLNFT.Collection at the specified path")
    }

    execute {
        let tokensToUpgrade: @[{NonFungibleToken.NFT}] <- []
        let burnTokens: @[{NonFungibleToken.NFT}] <- []

        for id in upgradeIds {
            let token: @{NonFungibleToken.NFT} <- self.usersCollectionRef.withdraw(withdrawID: id)
            tokensToUpgrade.append(<- token)
        }

        for id in burnIds {
            let token: @{NonFungibleToken.NFT} <- self.usersCollectionRef.withdraw(withdrawID: id)
            burnTokens.append(<- token)
        }

        let newTokens: @[{NonFungibleToken.NFT}] <- AFLUpgradeExchange.upgrade(tokens: <- tokensToUpgrade, burnTokens: <- burnTokens)

        while newTokens.length > 0 {
            self.usersCollectionRef.deposit(token: <-newTokens.removeFirst())
        }

        destroy newTokens
    }
}
`
