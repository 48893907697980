export const BUY_PACK = `import AFLAdmin from 0xAFLContracts
import AFLPack from 0xAFLContracts
import FungibleToken from 0xFungibleToken
import USDCFlow from 0xUSDCFlow

transaction(templateIds: [{String: UInt64}], packTemplateId: UInt64, price:UFix64, receiptAddress: Address) {
  let adminRef: auth(Storage, AFLPack.PackAdmin) &AFLPack.Pack
  let temporaryVault : @{FungibleToken.Vault}
  prepare(adminAccount: auth(Storage, AFLPack.PackAdmin) &Account, tokenRecipientAccount: auth(BorrowValue, FungibleToken.Withdraw) &Account){
      self.adminRef = adminAccount.storage.borrow<auth(Storage, AFLPack.PackAdmin) &AFLPack.Pack>(from: AFLPack.PackStoragePath)
          ??panic("could not borrow admin reference")
      let vaultRef = tokenRecipientAccount.storage.borrow<auth(BorrowValue, FungibleToken.Withdraw) &USDCFlow.Vault>(from: USDCFlow.VaultStoragePath)
              ??panic("could not borrow vault")
      self.temporaryVault <- vaultRef.withdraw(amount: price)
  }
  execute{
      self.adminRef.buyPack(templateIds: templateIds, packTemplateId: packTemplateId, receiptAddress: receiptAddress, price: price, flowPayment: <- self.temporaryVault)
  }
}`
