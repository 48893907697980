export const CANCEL_ALL_NFT_SALE = `
import AFLNFT from 0xAFLContracts
import FungibleToken from 0xFungibleToken;
import USDCFlow from 0xUSDCFlow;
import AFLMarketplaceV2 from 0xAFLContracts

transaction(){
  let collectionRef: &AFLNFT.Collection
  let AFLMarketplaceSaleCollectionRef: auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection

  prepare(account: auth(BorrowValue) &Account){
    let marketplaceCap: Capability<&AFLMarketplaceV2.SaleCollection> = account.capabilities.get<&AFLMarketplaceV2.SaleCollection>(/public/AFLMarketplaceSaleCollectionV2)
    self.collectionRef = account.storage.borrow<&AFLNFT.Collection>(from: /storage/AFLNFTCollection)!
    self.AFLMarketplaceSaleCollectionRef = account.storage.borrow<auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection>(from: /storage/AFLSaleCollection) ?? panic("could not borrow AFLMarketplace collection")
  }
  execute {
    for tokenID in self.AFLMarketplaceSaleCollectionRef.getIDs() {
      let token: @AFLNFT.NFT <- self.AFLMarketplaceSaleCollectionRef.withdraw(tokenID: tokenID)
      self.collectionRef.deposit(token: <- token)
    }
  }
}

`
