export const GET_ALL_NFTS_BY_ACCOUNT = `
import AFLNFT from 0xAFLContracts

access(all) fun main(addr:Address): AnyStruct {
  let account: &Account = getAccount(addr)
  let acctCapability: &{AFLNFT.AFLNFTCollectionPublic} =  account.capabilities.get<&{AFLNFT.AFLNFTCollectionPublic}>(AFLNFT.CollectionPublicPath)
                          .borrow()
                          ??panic("could not borrow receiver reference ")
  let nftIds: [UInt64] =  acctCapability.getIDs()
  return nftIds
}

`
