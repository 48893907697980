import { useEffect, useState } from 'react'

import * as fcl from '@blocto/fcl'
import { GET_TEMPLATE_BY_ID } from '../flow/scripts/getTemplateById'
import { Moment, NFT } from 'types'
import { getLowestPrices } from 'services/marketplace.api.service'

export enum MomentStatus {
  LOADING,
  IDLE,
  ERROR
}

const genericMomentCache: Record<string, NFT<Moment>> = {}

export default function useGenericMoment(id?: string) {
  const [moment, setMoment] = useState<NFT<Moment>>()
  const [status, setStatus] = useState<MomentStatus>(MomentStatus.IDLE)

  useEffect(() => {
    if (!id) return
    console.log('getting generic moment for moment id', id)
    const getPackMetadata = async () => {
      if (genericMomentCache[id]) {
        setMoment(genericMomentCache[id])
        return
      }
      setStatus(MomentStatus.LOADING)
      try {
        const res = await fcl.query({
          cadence: GET_TEMPLATE_BY_ID,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          args: (arg: any, t: any) => [arg(String(id), t.UInt64)]
        })

        const lowestPrice = await getLowestPrices({ templateIds: [id] })
        const m = {
          templateData: {
            templateId: res.templateId,
            issuedSupply: res.issuedSupply,
            immutableData: res.immutableData,
            forSale: lowestPrice[0] !== undefined
          }
        } as unknown as NFT<Moment>
        genericMomentCache[id] = m
        setMoment(m)
        setStatus(MomentStatus.IDLE)
      } catch (e) {
        console.error(e)
        setStatus(MomentStatus.ERROR)
        // add papertrail(e)
      }
    }
    getPackMetadata()
  }, [id])

  return {
    status,
    moment,
    issuedSupply: moment?.templateData.issuedSupply
  }
}
