export const DESTROY_COLLECTION = `
import AFLNFT from 0xAFLContracts

transaction {
  prepare(acct: auth(LoadValue, UnpublishCapability) &Account) {
    let collection: @AFLNFT.Collection <- acct.storage.load<@AFLNFT.Collection>(from: AFLNFT.CollectionStoragePath)
      ?? panic("No collection found")
    destroy collection

    acct.capabilities.unpublish(AFLNFT.CollectionPublicPath)
  }
}
`
