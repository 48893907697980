import React from 'react'
import { Grid } from '@mui/material'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import { useWallets } from 'providers/WalletsProvider'
import { getMoonpaySignature } from 'services/api.service'
import { Add } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material/styles'
interface MoonpayModalProps {
  open: boolean
  onClose: () => void
  mode: 'nft' | 'funds'
  packId?: string
}

const MoonpayModal = ({ open, onClose, packId, mode }: MoonpayModalProps) => {
  const { flowUser, getFlowWUSDCBalance } = useWallets()
  const [fullURL, setFullURL] = React.useState('')
  const [openDialog, setOpenDialog] = React.useState(false)
  function closeModal() {
    getFlowWUSDCBalance()
    onClose()
    setOpenDialog(false)
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  React.useEffect(() => {
    if (!flowUser?.addr) return
    if (mode === 'nft' && !packId) return

    const nftURL = `${import.meta.env.VITE_MOONPAY_BASE_URL}/nft?apiKey=${import.meta.env.VITE_MOONPAY_KEY
      }&contractAddress=${import.meta.env.VITE_AFL_CONTRACTS
      }&tokenId=${packId}&currencyCode=usdc_flow&theme=dark&baseCurrencyCode=aud&walletAddress=${flowUser?.addr
      }`
    const fundsURL = `${import.meta.env.VITE_MOONPAY_BASE_URL}?apiKey=${import.meta.env.VITE_MOONPAY_KEY
      }&currencyCode=usdc_flow&theme=dark&baseCurrencyCode=aud&walletAddress=${flowUser?.addr
      }`

    const url = mode === 'nft' ? nftURL : fundsURL
    const getFullURL = async () => {
      const fURL = await getMoonpaySignature(url)
      setFullURL(fURL)
    }
    getFullURL()
  }, [flowUser, packId, mode])

  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={handleClickOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Grid
            container
            justifyContent="center"
            xs={11}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            item
            className="overflow-visible w-[340px] !max-w-[340px] absolute right-0 min-h-[100%] text-center text-black bg-[#1c1c1e]"
          >
            <Grid item xs={11} className="pt-8 md:pt-10">
              <div
                onClick={handleClickOpen}
                className="flex absolute top-0 left-[-30px] justify-center items-center w-[30px] h-[30px] bg-white cursor-pointer"
              >
                <Add
                  fontSize="large"
                  style={{
                    transform: `rotate(45deg)`,
                    color: '#000000'
                  }}
                />
              </div>
              <iframe
                allow="accelerometer; autoplay; camera; gyroscope; payment"
                frameBorder="0"
                height="95%"
                src={fullURL}
                width="100%"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Exit the purchase'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to exit the process?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={closeModal}>Exit</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default MoonpayModal
