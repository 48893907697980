import { useEffect, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { Moment, NFT } from 'types'
import { GET_NFTS_IN_CHUNKS } from 'flow/scripts/getNFTsInChunks'

export enum MomentStatus {
  LOADING,
  IDLE,
  ERROR
}

export default function useMoments(ids: string[]) {
  const [moments, setMoments] = useState<Array<NFT<Moment>>>()
  const [status, setStatus] = useState<MomentStatus>(MomentStatus.IDLE)

  useEffect(() => {
    async function getAllMetadata(ids: string[]) {
      try {
        const iNFTs: NFT<Moment>[] = []
        const promises = []
        for (let i = 0; i < ids.length; i += 1000) {
          const smallArray = ids.slice(i, i + 1000)
          if (ids[i] === '0') {
            promises.push(Promise.resolve({ fakeMoment: { id: '0' } }))
          } else {
            promises.push(
              fcl.query({
                cadence: GET_NFTS_IN_CHUNKS,
                args: (arg: any, t: any) => [arg(smallArray, t.Array(t.UInt64))]
              })
            )
          }
        }
        const results = await Promise.all(promises)
        for (const result of results) {
          iNFTs.push(...(Object.values(result) as NFT<Moment>[]))
        }
        setMoments(iNFTs)
        setStatus(MomentStatus.IDLE)
      } catch (e) {
        // add papertrail(e)
        console.error(e)
        setStatus(MomentStatus.ERROR)
      }
    }

    getAllMetadata(ids)
  }, [ids?.length])

  return {
    status,
    moments
  }
}
