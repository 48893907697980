export const SWAP_DUPES = `
import AFLNFT from 0xAFLContracts
import AFLBurnExchange from 0xAFLContracts
import NonFungibleToken from 0xNFT
import FungibleToken from 0xFungibleToken

transaction(withdrawIDs: [UInt64]) {
  let usersCollectionRef: auth(NonFungibleToken.Withdraw) &AFLNFT.Collection

  prepare(signer: auth(BorrowValue, NonFungibleToken.Withdraw) &Account) {
      self.usersCollectionRef = signer.storage
          .borrow<auth(NonFungibleToken.Withdraw) &AFLNFT.Collection>(from: AFLNFT.CollectionStoragePath)
          ?? panic("Account does not store an AFLNFT.Collection at the specified path")
  }

  execute {
      for id in withdrawIDs {
          if (AFLBurnExchange.getTokenId(id: id) != 0) {
              let dupeNft: @{NonFungibleToken.NFT} <- self.usersCollectionRef.withdraw(withdrawID: id)
              let newNFT: @{NonFungibleToken.NFT} <- AFLBurnExchange.swap(token: <- dupeNft)
              self.usersCollectionRef.deposit(token: <-newNFT)
          }
      }
  }
}
`
