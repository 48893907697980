import useCollection from 'hooks/useCollection'
import { createContext, useContext, ReactNode } from 'react'
import useUserWallets from 'hooks/useUserWallets'
import { RegisteredWallet } from 'types'

interface State {
  registeredWallets: RegisteredWallet[]
  flowUser: any
  hasBlocto: boolean
  hasMagic: boolean
  bloctoWalletAddress: string
  magicWalletAddress: string
  magicMomentCount: number
  bloctoMomentCount: number
  connectedWalletAddress: string
  isConnected: boolean
  connect?: any
  connectBlocto: Function
  connectMagic: Function
  disconnect?: any
  collectionIsLoading: boolean
  collection: boolean | undefined
  createCollection: () => void
  destroyCollection: () => void
  flowWUSDCBalance: number,
  magicWalletWUSDCBalance: number
  bloctoWalletWUSDCBalance: number
  getFlowWUSDCBalance: () => void
  usingMagic: boolean
  userAuth: any
  magic: any
  completedLoadingWalletsInfo: boolean
  lastUsedWallet: string
  checkAndUpdateUsersRegisteredWallets: Function
  refreshWallets: Function
  magicForSaleMomentCount: number
  bloctoForSaleMomentCount: number
  magicNotForSaleMomentCount: number
  bloctoNotForSaleMomentCount: number
  showMagicWalletUI: Function
  // doLoggedInRedirect: boolean
}

interface WalletsContextProps {
  children: ReactNode
}

const walletsContextDefaultValues: State = {
  registeredWallets: [],
  flowUser: null,
  hasBlocto: false,
  hasMagic: false,
  magicWalletAddress: '',
  bloctoWalletAddress: '',
  connectedWalletAddress: '',
  magicMomentCount: 0,
  bloctoMomentCount: 0,
  connectBlocto: () => Promise,
  connectMagic: () => Promise,
  isConnected: false,
  connect: null,
  disconnect: null,
  collection: false,
  collectionIsLoading: true,
  createCollection: () => null,
  destroyCollection: () => null,
  flowWUSDCBalance: 0,
  magicWalletWUSDCBalance: 0,
  bloctoWalletWUSDCBalance: 0,
  getFlowWUSDCBalance: () => null,
  usingMagic: false,
  userAuth: null,
  magic: null,
  completedLoadingWalletsInfo: false,
  lastUsedWallet: 'magic',
  checkAndUpdateUsersRegisteredWallets: () => null,
  refreshWallets: () => null,
  magicForSaleMomentCount: 0,
  bloctoForSaleMomentCount: 0,
  magicNotForSaleMomentCount: 0,
  bloctoNotForSaleMomentCount: 0,
  showMagicWalletUI: () => null
  // doLoggedInRedirect: false
}

const WalletsContext = createContext<State>(walletsContextDefaultValues)

export default function WalletsProvider({ children }: WalletsContextProps) {
  const userWallets = useUserWallets()
  const collectionProps = useCollection(userWallets.flowUser)

  return (
    <WalletsContext.Provider
      value={{
        ...userWallets,
        ...collectionProps,
        connect: userWallets.tools?.connect,
        disconnect: userWallets.tools?.disconnect
      }}
    >
      {children}
    </WalletsContext.Provider>
  )
}

const useWallets = () => {
  const context = useContext(WalletsContext)
  if (context === undefined) {
    throw new Error('useWallets must be used within a WalletsContext')
  }
  return context
}

export { WalletsProvider, useWallets, WalletsContext }
