import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch'
import CustomButton from 'components/Button/CustomButton'
import useExchangeRate from 'hooks/useExchangeRate'
import { useSnackbar } from 'hooks/useSnackbar'
import copyIcon from '/assets/Icon/Copy.svg'
import bloctoIcon from '/assets/Icon/blocto.svg'
import magicIcon from '/assets/Icon/Logo-Magic.svg'
import { useWallets } from 'providers/WalletsProvider'
import SetupWalletModal from 'components/Modal/SetupWalletModal'
import SpinnerModal from 'components/Modal/SpinnerModal'
import PaymentModal from 'components/Modal/PaymentModal'
import Dot from '/assets/Icon/Dot.svg'

const GET_BLOCTO_APP_URL = 'https://blocto.io/download'

interface WalletCardProps {
  walletName: 'Magic' | 'Blocto'
  showForSaleBreakdown?: boolean
}

function WalletCard({
  walletName,
  showForSaleBreakdown = false
}: WalletCardProps) {
  const { openSnackbar } = useSnackbar()
  const { rate } = useExchangeRate()
  const {
    hasMagic,
    hasBlocto,
    magicWalletAddress,
    bloctoWalletAddress,
    connectedWalletAddress,
    magicWalletWUSDCBalance,
    bloctoWalletWUSDCBalance,
    magicMomentCount,
    bloctoMomentCount,
    isConnected,
    connectMagic,
    connectBlocto,
    disconnect,
    usingMagic,
    magicForSaleMomentCount,
    bloctoForSaleMomentCount,
    magicNotForSaleMomentCount,
    bloctoNotForSaleMomentCount,
    showMagicWalletUI
  } = useWallets()
  const [spinner, setSpinner] = useState(false)
  const [setupWalletModalOpen, setSetupWalletModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isMagic = walletName === 'Magic'
  const hasAddedWallet = isMagic ? hasMagic : hasBlocto
  const walletIcon = isMagic ? magicIcon : bloctoIcon
  const totalCollectiblesInAccount = isMagic
    ? magicMomentCount
    : bloctoMomentCount
  const forSaleCollectiblesInAccount = isMagic
    ? magicForSaleMomentCount
    : bloctoForSaleMomentCount
  const notForSaleCollectiblesInAccount = isMagic
    ? magicNotForSaleMomentCount
    : bloctoNotForSaleMomentCount
  const walletAddress = isMagic ? magicWalletAddress : bloctoWalletAddress
  const _isConnected = isMagic
    ? usingMagic && isConnected
    : !usingMagic && isConnected
  const flowWUSDCBalance = isMagic
    ? magicWalletWUSDCBalance ?? magicWalletWUSDCBalance
    : bloctoWalletWUSDCBalance ?? bloctoWalletWUSDCBalance

  const userBalanceAUD = Number(flowWUSDCBalance * rate).toFixed(2)
  const USDCBalance = Number(flowWUSDCBalance).toFixed(2)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConnectClick = () => {
    if (!_isConnected && !hasAddedWallet) {
      handleGetStarted()
      return
    }

    if (isMagic) {
      handleMagicConnect()
    } else {
      handleBloctoConnect()
    }
    handleClose()
  }

  const handleMagicConnect = () => {
    if (!_isConnected) {
      connectMagic()
      setSpinner(true)
    } else {
      disconnect()
    }
  }

  const handleBloctoConnect = () => {
    if (!_isConnected) {
      connectBlocto()
    } else {
      disconnect()
    }
  }

  const handleGetStarted = () => {
    setSetupWalletModalOpen(true)
  }

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(walletAddress)
    openSnackbar('Copied to clipboard')
  }

  const handleOpenPaymentModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (isConnected || connectedWalletAddress === magicWalletAddress) {
      setSpinner(false)
    }
  }, [connectedWalletAddress, magicWalletAddress, isConnected])

  return (
    <div className="flex flex-col gap-2 justify-between p-4 w-full bg-card rounded-[3px]">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex gap-3 justify-between items-start w-full">
          <div className="flex flex-col gap-1 justify-center items-start w-full">
            <div className="flex justify-between items-center -mt-2 w-full font-bold">
              <div className="flex gap-2 items-center">
                <img
                  src={walletIcon}
                  alt={`Logo ${walletName}`}
                  className="w-[20px] h-6"
                />
                <div className="text-base text-white">{walletName}</div>
              </div>
              <Switch
                sx={{
                  padding: '6px',
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#ffffff'
                  },
                  '& .MuiSwitch-track': {
                    borderRadius: '16px',
                    backgroundColor: '#7E7E7E'
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#48a0ff'
                  }
                }}
                checked={_isConnected}
                onChange={handleConnectClick}
              />
            </div>
            <div
              className="flex gap-1 items-start text-sm text-[#a6a6a6]"
              onClick={copyWalletAddress}
            >
              <div>{walletAddress}</div>
              {walletAddress && (
                <img
                  src={copyIcon}
                  alt="Copy Wallet Icon"
                  className="w-4 cursor-pointer"
                />
              )}
            </div>
          </div>
        </div>
        {hasAddedWallet === false && (
          <>
            <div className="flex flex-col gap-2 items-start py-2 w-full border-t border-[#3e3e3e]">
              <div className="flex gap-2 justify-between items-start w-full text-[#d3d3d3]">
                {isMagic
                  ? 'Magic is a new wallet option that reduces log in and purchase steps for a more seamless experience.'
                  : 'Blocto wallet is an alternative wallet for xxxxxxx'}
              </div>
            </div>
          </>
        )}
        {hasAddedWallet && (
          <div className="flex flex-col gap-2 items-start py-2 w-full border-t border-[#3e3e3e]">
            <div className="flex gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                {showForSaleBreakdown && (
                  <>
                    <div className="text-sm font-bold">
                      Collectibles (not for sale)
                    </div>
                    <div className="text-sm font-bold">
                      Collectibles (for sale)
                    </div>
                  </>
                )}
                {!showForSaleBreakdown && (
                  <div className="text-sm font-bold">Collectibles</div>
                )}
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                {showForSaleBreakdown && (
                  <>
                    <div className="font-bold">
                      {notForSaleCollectiblesInAccount}
                    </div>
                    <div className="font-bold">
                      {forSaleCollectiblesInAccount}
                    </div>
                  </>
                )}
                {!showForSaleBreakdown && (
                  <div className="font-bold">{totalCollectiblesInAccount}</div>
                )}
              </div>
            </div>
            <div className="flex relative gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                <div className="text-sm font-bold">Balance</div>
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                <div className="font-bold">A${userBalanceAUD}</div>
                <div className="text-grey2">({USDCBalance} USDC)</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {_isConnected ? (
        <CustomButton
          label="Add funds"
          model="primary"
          variant="contained"
          onClick={handleOpenPaymentModal}
        />
      ) : (
        <CustomButton
          label={hasAddedWallet ? 'Connect Wallet' : 'Get Started'}
          model="secondary"
          variant="contained"
          onClick={handleConnectClick}
        />
      )}
      {_isConnected && isMagic && (
        <CustomButton
          label="Manage Funds"
          model="secondary"
          variant="outlined"
          onClick={() => showMagicWalletUI()}
        />
      )}
      {_isConnected && !isMagic && (
        <a href={GET_BLOCTO_APP_URL} target="_blank" rel="noreferrer">
          <CustomButton
            label="Manage Funds (Get App)"
            model="secondary"
            variant="outlined"
            isExternalLink={true}
            className="w-full"
          />
        </a>
      )}
      <SetupWalletModal
        isMagic={isMagic}
        setupWalletModalOpen={setupWalletModalOpen}
        setSetupWalletModalOpen={setSetupWalletModalOpen}
      />
      <SpinnerModal
        spinnerModalOpen={spinner}
        setSpinnerModalOpen={setSpinner}
      />
      <PaymentModal
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        updateActiveStep={2}
        checkoutType="funds"
      />
    </div>
  )
}
export default WalletCard
