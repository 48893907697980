import { Button, Drawer, Grid } from '@mui/material'
import CustomButton from 'components/Button/CustomButton'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo1 from '/assets/logo/Mint-logo.png'
import { useAuth } from '../providers/AuthProvider'
import { useState, useEffect } from 'react'
import MenuIcon from 'components/Button/Menu'
import profilePicture from '/assets/Icon/profile.svg'
import ListItem from '@mui/material/ListItem'
import UserMenu from './UserMenu'
import discord from '/assets/Icon/Discord.svg'
import twitter from '/assets/Icon/Twitter.svg'
import instagram from '/assets/Icon/Instagram.svg'
import facebook from '/assets/Icon/Facebook.svg'
import youtube from '/assets/Icon/Youtube.svg'
import { clearNotifications, getNotifications } from 'utils/notifications'
import { Add } from '@mui/icons-material'
import Coach from 'components/DataDisplay/Coach'
import LiveBanner from 'components/Banner/LiveBanner'
import usePack from 'hooks/usePack'
import CountDown, { CountdownRenderProps } from 'react-countdown'
import { useWallets } from 'providers/WalletsProvider'
import useMarketplaceListing from 'hooks/useMarketplaceListing'

const Nav = () => {
  const { loggedIn, logIn, isDevLoggedIn, setIsDevLoggedIn, signUp } = useAuth()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const { isConnected, flowWUSDCBalance } = useWallets()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)
  const { pack } = usePack(import.meta.env.VITE_CHAMPS_2023_4_ID)
  const isDropSoldOut =
    Number(pack?.maxSupply || 1) <= Number(pack?.issuedSupply || 0)
  const [isDropLive, setIsDropLive] = useState(
    new Date() > new Date(import.meta.env.VITE_COUNTDOWN_TIME)
  )
  const bannerName = 'wusdcBanner'
  const [stickyMode, setStickyMode] = useState(
    localStorage.getItem(`bannerDismissed-${bannerName}`) ? false : true
  )
  const localDev = import.meta.env.VITE_DEV_ENVIRONMENT === 'true'
  // const [isBannerDismissed, setIsBannerDismissed] = useState(
  //   localStorage.getItem(`bannerDismissed-${bannerName}`)
  //   // true
  // )

  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(
    // isDropLive && !isDropSoldOut && !isBannerDismissed
    // isConnected && flowWUSDCBalance > 0.0 ? true : false
    false
  )

  const { isMarketplaceSetup, setupMarketplace } = useMarketplaceListing()

  const setCompletedCountdown = ({
    completed
  }: CountdownRenderProps): React.ReactNode => {
    if (completed) {
      setIsDropLive(true)
    } else {
      return null
    }
  }

  useEffect(() => {
    setIsBannerOpen(isConnected && !isMarketplaceSetup)
  }, [isConnected, isMarketplaceSetup])

  // useEffect(() => {
  //   if (isDropLive && !isDropSoldOut && !isBannerDismissed) {
  //     setIsBannerOpen(true)
  //   }
  // }, [isDropLive, isDropSoldOut, isBannerDismissed])

  useEffect(() => {
    const changeColour = () => {
      if (window.scrollY >= 50 || isBannerOpen) {
        setStickyMode(true)
      } else {
        setStickyMode(false)
      }
    }
    window.addEventListener('scroll', changeColour)
    return () => {
      window.removeEventListener('scroll', changeColour)
    }
  }, [isBannerOpen])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setStickyMode(isBannerOpen ? true : false)
  }, [isBannerOpen])

  useEffect(() => {
    setNotificationMessage(getNotifications().newPacks ?? '')
  }, [location])

  const dismissNotifications = () => {
    clearNotifications()
  }

  return (
    <>
      <div
        className={`w-full transition-all duration-300 ${
          isBannerOpen ? 'h-[50px]' : 'h-[0px]'
        }`}
      ></div>
      {/* <CountDown
        date={import.meta.env[`VITE_COUNTDOWN_TIME`]}
        renderer={setCompletedCountdown}
      /> */}
      <Grid
        container
        className={`fixed top-0 z-[90] items-center  ${
          stickyMode ? 'nav nav-bg' : 'nav'
        } ${isBannerOpen ? 'h-[130px]' : 'h-[80px]'} `}
      >
        {/* drop live banner */}
        <div
          className="flex justify-center items-center w-full cursor-pointer"
          onClick={() => setupMarketplace()}
        >
          <LiveBanner
            bannerName={bannerName}
            notification={
              isMobile
                ? 'Setup Marketplace Listings'
                : 'Quick action needed: Setup Marketplace Listings '
            }
            isBannerOpen={isBannerOpen}
            setIsBannerOpen={setIsBannerOpen}
          />
        </div>

        {/* regular menu */}
        <div
          className={`hidden justify-between items-center w-full pt-6 pb-7 text-sm font-bold tracking-[0.1em] smd:flex global-padding`}
        >
          <Grid item className=" w-52">
            <Link to="/">
              <img src={logo1} alt="AFL Mint Logo" className="w-52" />
            </Link>
          </Grid>
          <Grid item className="text-white">
            <NavLink to="/packs/" className="p-4 font-podium49 nav">
              PACKS
            </NavLink>
            <NavLink to="/collections" className="p-4 font-podium49 nav">
              COLLECTIONS
            </NavLink>
            <NavLink to="/marketplace" className="p-4 font-podium49 nav">
              MARKETPLACE
            </NavLink>
            <NavLink to="/mint-guide" className="p-4 font-podium49 nav">
              GUIDE
            </NavLink>
            {localDev === true && (
              <NavLink
                to="#"
                className="p-4 font-podium49 nav"
                onClick={() => {
                  localStorage.setItem(
                    'devIsLoggedIn',
                    !isDevLoggedIn ? 'true' : 'false'
                  )
                  setIsDevLoggedIn(!isDevLoggedIn)
                }}
              >
                DEV LOGIN/LOGOUT
              </NavLink>
            )}
            {loggedIn ? (
              <NavLink
                to="/trophy-cabinet"
                className="relative p-4 font-podium49 nav"
                onClick={() => dismissNotifications()}
              >
                TROPHY CABINET
                {notificationMessage && (
                  <div
                    className={` absolute top-[0] right-0 justify-center items-center w-[18px] h-[18px] bg-blue2 rounded-[50%] ${
                      stickyMode ? 'hidden' : 'flex '
                    }`}
                  >
                    <p className="text-xs font-bold text-center text-white">
                      {notificationMessage}
                    </p>
                  </div>
                )}
                {notificationMessage && (
                  <div
                    className={`absolute top-12 right-1/2 translate-x-[50%] ${
                      stickyMode ? 'hidden' : 'flex '
                    }`}
                    onClick={() => dismissNotifications()}
                  >
                    <Coach>
                      <div className="pb-4 font-inter text-[13px] tracking-wide leading-[18px]">
                        Your new item is now in your Trophy Cabinet
                      </div>
                      <div className="flex justify-end w-full">
                        <CustomButton
                          type="button"
                          model="secondary"
                          size="small"
                          variant="contained"
                          label="Take a look"
                        />
                      </div>
                    </Coach>
                  </div>
                )}
              </NavLink>
            ) : (
              ''
            )}
          </Grid>
          {!loggedIn ? (
            <Grid item className="flex flex-row justify-end items-center w-52">
              <button
                onClick={logIn}
                className="pr-5 font-podium49 tracking-widest text-white"
              >
                LOG IN
              </button>
              <CustomButton
                type="button"
                model="primary"
                variant="contained"
                label="SIGN UP"
                onClick={signUp}
                className="h-8 !font-podium49 !tracking-widest uppercase"
              />
            </Grid>
          ) : (
            <Grid item className="w-52">
              <UserMenu />
            </Grid>
          )}
        </div>
        {/* mobile menu */}
        <div
          className={`flex justify-between items-center w-full px-5 py-5 text-sm font-bold tracking-[0.1em] smd:hidden `}
        >
          <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              overflow: 'visible',
              '& .MuiPaper-root': {
                backgroundColor: 'transparent',
                overflowY: 'auto',
                overflowX: 'visible',
                boxShadow: 'none'
              }
            }}
            className="modal-bg"
          >
            <div className="flex h-full">
              <div
                onClick={() => setOpen(false)}
                className="flex justify-center items-center w-[30px] h-[30px] bg-white cursor-pointer"
              >
                <Add
                  fontSize="large"
                  style={{
                    transform: `rotate(45deg)`,
                    color: '#000000'
                  }}
                />
              </div>
              <div className="w-72 bg-black1">
                <div className="flex relative flex-col justify-between pt-10 h-[80vh] text-white">
                  <Grid>
                    <ListItem>
                      <NavLink
                        to="/packs/"
                        className="mb-3 font-podium49 tracking-wider"
                        onClick={() => setOpen(false)}
                        style={{ fontSize: '28px', lineHeight: '28px' }}
                      >
                        PACKS
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        to="/collections"
                        className="mb-3 font-podium49 tracking-wider"
                        onClick={() => setOpen(false)}
                        style={{ fontSize: '28px', lineHeight: '28px' }}
                      >
                        COLLECTIONS
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        to="/marketplace"
                        className="mb-3 font-podium49 tracking-wider"
                        onClick={() => setOpen(false)}
                        style={{ fontSize: '28px', lineHeight: '28px' }}
                      >
                        MARKETPLACE
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        to="/learn"
                        className="mb-3 font-podium49 tracking-wider"
                        onClick={() => setOpen(false)}
                        style={{ fontSize: '28px', lineHeight: '28px' }}
                      >
                        LEARN
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        to="/mint-guide"
                        className="mb-3 font-podium49 tracking-wider"
                        onClick={() => setOpen(false)}
                        style={{ fontSize: '28px', lineHeight: '28px' }}
                      >
                        GUIDE
                      </NavLink>
                    </ListItem>
                    {loggedIn ? (
                      <ListItem>
                        <NavLink
                          to="/trophy-cabinet"
                          className="mb-3 font-podium49 tracking-wider"
                          onClick={() => setOpen(false)}
                          style={{ fontSize: '28px', lineHeight: '28px' }}
                        >
                          TROPHY CABINET
                        </NavLink>
                      </ListItem>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid>
                    {!loggedIn ? (
                      <Grid item>
                        <ListItem>
                          <button
                            onClick={() => {
                              logIn()
                              setOpen(false)
                            }}
                            className="text-sm font-bold tracking-wide"
                          >
                            Sign Up
                          </button>
                        </ListItem>
                        <ListItem>
                          <button
                            onClick={() => {
                              logIn()
                              setOpen(false)
                            }}
                            className="text-sm font-bold tracking-wide"
                          >
                            Log In
                          </button>
                        </ListItem>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <ListItem>
                      <Link
                        to="/roadmap"
                        className=" text-sm font-bold tracking-wide"
                      >
                        Roadmap
                      </Link>
                    </ListItem>
                    <ListItem>
                      <a
                        className=" text-sm font-bold tracking-wide"
                        href="https://aflmint.zendesk.com/hc/en-gb"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Help Centre
                      </a>
                    </ListItem>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      className="p-2 "
                      columnSpacing={2}
                    >
                      <Grid item sx={{ py: '5px' }}>
                        <a
                          href="https://discord.com/invite/aflmintofficial"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={discord}
                            alt=""
                            className="social-icon-nav"
                          />
                        </a>
                      </Grid>
                      <Grid item sx={{ py: '5px' }}>
                        <a
                          href="https://twitter.com/aflmint"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={twitter}
                            alt=""
                            className="social-icon-nav"
                          />
                        </a>
                      </Grid>
                      <Grid item sx={{ py: '5px' }}>
                        <a
                          href="https://www.instagram.com/aflmint/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={instagram}
                            alt=""
                            className="social-icon-nav"
                          />
                        </a>
                      </Grid>
                      <Grid item sx={{ py: '5px' }}>
                        <a
                          href="https://www.youtube.com/channel/UCT1E0Rmm6_DY99dRTFI1hyQ"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={youtube}
                            alt=""
                            className="social-icon-nav"
                          />
                        </a>
                      </Grid>
                      <Grid item sx={{ py: '5px' }}>
                        <a
                          href="https://facebook.com/aflmint"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={facebook}
                            alt=""
                            className="social-icon-nav"
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Drawer>

          <Grid item className="w-36 xsm:w-52">
            <Link to="/">
              <img src={logo1} alt="AFL Mint Logo" />
            </Link>
          </Grid>
          <div className="flex">
            {!loggedIn ? (
              <Grid item className="flex flex-row justify-center items-center">
                <button
                  onClick={logIn}
                  className="hidden mr-4 font-podium49 tracking-widest text-white sm:block"
                >
                  LOG IN
                </button>
                <CustomButton
                  type="button"
                  model="primary"
                  variant="contained"
                  label="SIGN UP"
                  className="!hidden h-8 !font-podium49 !tracking-widest uppercase sm:!block"
                  onClick={logIn}
                />
                <button onClick={logIn}>
                  <img src={profilePicture} alt="" className="h-7 sm:hidden" />
                </button>
              </Grid>
            ) : (
              <Grid item>
                <UserMenu />
              </Grid>
            )}
            <button className="ml-2 sm:ml-4" onClick={() => setOpen(true)}>
              <MenuIcon fontSize="large" />
            </button>
          </div>
        </div>
      </Grid>
    </>
  )
}

export default Nav
