export const LIST_NFT = `
import AFLNFT from 0xAFLContracts
import FungibleToken from 0xFungibleToken
import NonFungibleToken from 0xNFT
import USDCFlow from 0xUSDCFlow
import AFLMarketplaceV2 from 0xAFLContracts
import MetadataViews from 0xMetadataViews

transaction(nftID: UInt64, price: UFix64) {
    let collectionRef:
        auth(NonFungibleToken.Withdraw)
        &AFLNFT.Collection
    let AFLMarketplaceSaleCollectionRef:
        auth(AFLMarketplaceV2.Owner)
        &AFLMarketplaceV2.SaleCollection

    prepare(acct: auth(AFLMarketplaceV2.Owner, BorrowValue, SaveValue, LoadValue, IssueStorageCapabilityController, PublishCapability, UnpublishCapability) &Account) {
        let marketplaceCap = acct.capabilities.get<&AFLMarketplaceV2.SaleCollection>(/public/AFLMarketplaceSaleCollectionV2)

        // setup account if required
        if !marketplaceCap.check() {

          // usdc vault check
          if acct.storage.borrow<&USDCFlow.Vault>(from: USDCFlow.VaultStoragePath) == nil {
            acct.storage.save(
              <-USDCFlow.createEmptyVault(vaultType: Type<@USDCFlow.Vault>()),
              to: USDCFlow.VaultStoragePath
            )
          }

          let receiver = acct.capabilities.storage.issue<&USDCFlow.Vault>(
            USDCFlow.VaultStoragePath
          )
          acct.capabilities.unpublish(USDCFlow.ReceiverPublicPath)
          acct.capabilities.publish(receiver, at: USDCFlow.ReceiverPublicPath)

          // Create a public capability to the Vault that only exposes
          // the balance field through the Balance interface
          acct.capabilities.unpublish(USDCFlow.VaultPublicPath)
          acct.capabilities.publish(receiver, at: USDCFlow.VaultPublicPath)

          let wallet = acct.capabilities.get<&USDCFlow.Vault>(USDCFlow.ReceiverPublicPath)

          // check if the account has a collection in storage, if not create and store it
          if(acct.storage.borrow<&AFLMarketplaceV2.SaleCollection>(from: /storage/AFLSaleCollectionV2) == nil) {
              let sale: @AFLMarketplaceV2.SaleCollection <- AFLMarketplaceV2.createSaleCollection(ownerVault: wallet)
              acct.storage.save<@AFLMarketplaceV2.SaleCollection>(<-sale, to: /storage/AFLSaleCollectionV2)
          }

          let marketSalePublicCap: Capability<&AFLMarketplaceV2.SaleCollection> = acct.capabilities.storage.issue<&AFLMarketplaceV2.SaleCollection>(/storage/AFLSaleCollectionV2)
          acct.capabilities.unpublish(AFLMarketplaceV2.SaleCollectionPublicPath)
          acct.capabilities.publish(marketSalePublicCap, at: AFLMarketplaceV2.SaleCollectionPublicPath)
        }

        self.collectionRef = acct.storage.borrow<auth(NonFungibleToken.Withdraw) &AFLNFT.Collection>(from: /storage/AFLNFTCollection)
                ??panic("could not borrow AFLNFT collection")

        self.AFLMarketplaceSaleCollectionRef = acct.storage.borrow<auth(AFLMarketplaceV2.Owner) &AFLMarketplaceV2.SaleCollection>(from: /storage/AFLSaleCollectionV2)
                ??panic("could not borrow AFLMarketplaceV2 collection")
    }

    execute {
        // withdraw the moment to put up for sale
        let token: @AFLNFT.NFT <- self.collectionRef.withdraw(withdrawID: nftID) as! @AFLNFT.NFT

        // the the moment for sale
        self.AFLMarketplaceSaleCollectionRef.listForSale(token: <-token, price: UFix64(price))
    }
}
`
